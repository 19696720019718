import {Component, OnInit} from '@angular/core';
import {SharedAuthService} from './shared/services/shared-auth.service';
import {UtilService} from './shared/services/util.service';
import {SpinnerService} from './shared/components/spinner/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading = false;

  constructor(private sharedAuthService: SharedAuthService,
              private utilService: UtilService,
              private spinnerService: SpinnerService,
  ) {
  }

  ngOnInit() {

    this.spinnerService.loading$.subscribe((loading) => {
      this.isLoading = loading;
    });

    this.sharedAuthService.setCurrentUserOrganization();
    this.sharedAuthService.setCurrentUserFirestoreData();
    this.utilService.addBranchIo();
  }
}
