import {Component, OnInit} from '@angular/core';
import {SharedAuthService} from '../../services/shared-auth.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

interface IntercomSettings {
  api_base: string;
  app_id: string;
  user_id?: string;
  email?: string;
}

@Component({
  selector: 'app-intercom',
  templateUrl: './intercom.component.html',
  styleUrls: ['./intercom.component.scss']
})
export class IntercomComponent implements OnInit {
  displayMessenger: boolean;

  constructor(
    private router: Router,
    private sharedAuthService: SharedAuthService
  ) {
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if ((this.router.url === '/' || this.router.url.includes('/partner')) && this.router.url !== '/partner/survey') {
        this.displayMessenger = true;
      } else {
        this.displayMessenger = false;
      }
    });
  }

  addIntercomMessenger(): void {
    this.sharedAuthService.getCurrentUserDecodedIdToken().then(token => {
      const settings: IntercomSettings = {
        app_id: 'w1oupu8i',
        api_base: 'https://api-iam.intercom.io'
      };
      /*      if (token?.user_id && token?.email) {
              settings.user_id = token.user_id;
              settings.email = token.email;
            }*/

      const head = document.getElementsByTagName('head')[0];
      const js = document.createElement('script');
      js.type = 'text/javascript';
      js.appendChild(document.createTextNode(`var APP_ID = "w1oupu8i";
        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                var l = function () {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                };
                if (document.readyState === 'complete') {
                    l();
                } else if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })();
        window.Intercom('boot', ${JSON.stringify(settings)});
        Intercom('show');`));

      head.appendChild(js);
      // Check if Intercom is ready
      window.setInterval(() => {
        if (window.Intercom) {
          this.displayMessenger = false;
        }
      }, 500);
    });
  }

}
