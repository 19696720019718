import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';


import {DatePipe} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {RouterModule, Routes} from '@angular/router';
/*import {AppUnavailableInGuard} from './auth/routeGuard/app-unavailable-in.guard';*/
/*import {AppAvailableInGuard} from './auth/routeGuard/app-available-in.guard';*/
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppCheckTokenInterceptor} from './shared/interceptor/app-check-token.interceptor';
/*import {MaintenanceOffRouteGuard} from './auth/routeGuard/maintenance/maintenance-off-route.guard';
import {MaintenanceOnRouteGuard} from './auth/routeGuard/maintenance/maintenance-on-route.guard';*/
import {SharedAuthService} from './shared/services/shared-auth.service';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {SharedModule} from './shared/shared.module';

import {initializeApp} from 'firebase/app';
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';
import {getApp} from 'firebase/app';

initializeApp(environment.firebase);
initializeAppCheck(getApp(), {
  provider: new ReCaptchaV3Provider(environment.appCheckSiteKey),
});


const routes: Routes = [
  {
    path: '',
    /*canActivate: [AppUnavailableInGuard, MaintenanceOnRouteGuard],*/
    loadChildren: () => import('./app-routing.module').then(m => m.AppRoutingModule),
  },
/*  {
    path: 'unavailable',
    canActivate: [AppAvailableInGuard],
    loadChildren: () => import('./auth/app-unavailable/app-unavailable.module').then(m => m.AppUnavailableModule),
  },*/
/*  {
    path: 'maintenance',
    loadChildren: () => import('./auth/maintenance/maintenance.module').then(m => m.MaintenanceModule),
    canActivate: [MaintenanceOffRouteGuard]
  },*/
  {path: '**', redirectTo: ''}
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'}),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SharedModule
  ],
  providers: [
/*    AppUnavailableInGuard,
    AppAvailableInGuard,*/
    SharedAuthService,
    DatePipe,
/*    MaintenanceOffRouteGuard,
    MaintenanceOnRouteGuard,*/
    {provide: HTTP_INTERCEPTORS, useClass: AppCheckTokenInterceptor, multi: true}, // http interceptor (inject bearer token)
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
